<template>
  <v-data-table :headers="headers" :items="users" class="elevation-1"  :search="search">
     <template v-slot:[`item.etat`]="{ item }">
       <v-switch v-model="item.etat" ></v-switch>
    </template>
    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>GESTION DES UTILISATEURS</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" >Ajouter un utiliateur</v-btn>
          </template>
          <v-card>
            <v-card-title><span class="text-h5">{{ formTitle }}</span></v-card-title>
            
            <!-- modal -->
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.prenom" label="Prénom"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.nom" label="Nom"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.login" label="Login"></v-text-field>
                  </v-col>
                   <v-col
        class="d-flex"
        cols="12"
        sm="6"
      >
        <v-select
          :items="profils"
          item-value="last"
           item-text="libelle"
           label="profil"
           v-model="editedItem.profil"
          solo
        ></v-select>
      </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Etes vous sur de vouloir supprimer cet utilisateur ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
      >
        mdi-reload
      </v-icon>   
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
     <!--  <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>
<script>
  export default {
    data: () => ({
       search: '',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Prénom', sortable: true, value: 'nom',},
        { text: 'Nom', value: 'prenom' },
        { text: 'Email', value: 'email' },
        { text: 'Login', value: 'login' },
        { text: 'Profil', value: 'profil' },
        { text: 'Etat', value: 'etat' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items:['test','sse','eeeee'],
      users: [],
      profils:[],
      editedIndex: -1,
      editedItem: {
        nom: '',
        prenom: '',
        email: '',
        login: '',
        profil:'',
        etat:'',
      },
      defaultItem: {
        nom: '',
        prenom: '',
        email: '',
        login: '',
        profil:'',
        etat:true,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter un utilisateur' : 'Modifier un utilisateur'
      },
    },
    mounted(){
      fetch("http://localhost:3000/users")
      .then(res=>res.json())
      .then(data => this.users = data)
      .catch(err => console.log(err.message))



       fetch("http://localhost:3000/profils")
      .then(res=>res.json())
      .then(data => this.profils = data)
      .catch(err => console.log(err.message))
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
     /*  this.initialize() */
    },

    methods: {


      editItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.users.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem)
        } else {
          this.users.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>